import React from "react";
// import { useHistory } from "react-router-dom";

const Page404 = () => {

  return (
    <div id="page404">
      <h1>404 - Страница не найдена</h1>
      <a className="button link-to-main" href="/">Перейти на главную</a>
    </div>
    )
}

export default Page404;