

// used with context, to update part of current user data object:
// ! do not forget to import:
//   import { MainContext } from "./MainContext"
//   const [currentUserData, setCurrentUserData] = useContext(MainContext)
const updateUserData = (currentContext, contextUpdaterFunction, newDataObject) => {
    let newUserData = Object.assign(currentContext, newDataObject);
    contextUpdaterFunction(newUserData);
  }


async function fetchWithTimeout(resource, options) {
    const { timeout = 8000 } = options;
    
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
  
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal  
    });
    clearTimeout(id);
  
    return response;
}
  
export {
    updateUserData,
    fetchWithTimeout
  };