import React, { useState, useRef, useEffect, useContext } from "react";
import { useForm, useFieldArray, Controller } from 'react-hook-form';

import { matchSorter } from "match-sorter";
import { ErrorMessage } from '@hookform/error-message';

import CreatableSelect from 'react-select/creatable';
import Select, { createFilter } from 'react-select';

import { MainContext } from "../MainContext";
import { fetchWithTimeout } from "../littleHelpers";
import { provideBrandsData } from "../provideData";
// import brandOptions from "./vehiclesBrands";
import LoadingIcon from "../LoadingIcon";

const categoryOptions = [
    { value: "Легковой", label: "Легковой" },
    { value: "До 3.5 тонн", label: "До 3.5 тонн" },
    { value: "Более 3.5 тонн", label: "Более 3.5 тонн" },
    { value: "Длинномеры > 8 м", label: "Длинномеры > 8 м" },
    { value: "8 кубов конт.", label: "8 кубов конт." },
    { value: "20 кубов конт.", label: "20 кубов конт." },
    { value: "27 кубов конт.", label: "27 кубов конт." },
    { value: "32 кубов конт.", label: "32 кубов конт." },
  ]

const reasonableFilterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    stringify: option => `${option.label}`,
    trim: true,
}


const MainForm = ({ addClassName = "" }) => {
    const [currentUserData] = useContext(MainContext);
    const [formState, setFormState] = useState('readyForInput');
    // ! formState can be:
    // readyForInput
    // sendingData
    // success
    // errorOnSubmit
    const [brandsOptions, setBrandsOptions] = 
    useState(
        JSON.parse(
            window.localStorage.getItem("brandsOptions"))?.arrayOfOptions);
    const allBrandsOptionsRef = useRef([]);        
    const errorData = useRef();

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
      } = useForm({
        criteriaMode: "all",
      });
    
    const { fields, append, remove } = useFieldArray({
        control, 
        name: "vehicles",
    })

    const firstRender = useRef(true);
    const defaultVehicleObject = useRef({
        number: "",
        brand: "",
        category: "",
        targetAreaID: "",
        comment: "",
    })


    useEffect(() => {
        let applicantName = currentUserData?.name;

        if ( firstRender.current === true && applicantName !== undefined && formState === "readyForInput" ) {
            provideBrandsData(setBrandsOptions, allBrandsOptionsRef);
            append(defaultVehicleObject.current)
            firstRender.current = false
        } 

    }, [formState, currentUserData])

    const onBrandsInputChange = (value, {action}) => {
        if (action === 'input-change') {
            let filteredOptions = matchSorter(brandsOptions, value, { keys: ['label']});
            setBrandsOptions(filteredOptions);
    
            if (value === "" || value === []) {
                setBrandsOptions(allBrandsOptionsRef.current);
            }
    
        } else if (action === 'menu-close') {
            setBrandsOptions(allBrandsOptionsRef.current);   
        }

    }
    
    const saveFormData = async (data) => {
        window.scrollTo(0, 0);

        const response = await fetchWithTimeout('https://system.landshaft.life/webhook/builder-form-noco', {
            timeout: 35000,
            method: 'POST',
            body: JSON.stringify(data)
        });

        if (response.status !== 200) {
            console.log("response with error");
            console.log(response);
            // throw new Error(`Ошибка соединения: ${response.status}`);
            errorData.current = response;
            throw new Error(response); 
        } else {
            remove();
            setFormState("success")
        }

    }

    const onSubmit = async (data) => {
        // mutating data object to set destination location 
        // and adding applicant's name to all comments fields
        // for all vehicles objects only on submit:
        let targetLocation;
        let applicantName = currentUserData.name;

        if (currentUserData.targetAreaID !== undefined) {
            targetLocation = currentUserData.targetAreaID;
        } else {
            targetLocation = Object.values(currentUserData.areas)[0];
        }

        data.vehicles.forEach(vehicleObject => {
            vehicleObject.targetAreaID = targetLocation;
            vehicleObject.comment = vehicleObject.comment + `${vehicleObject.comment.length > 0 ? " " : ""}` + `[заявка от: ${applicantName}]`;
        });

        // trying to send data to n8n webhook:
        try {
            
            setFormState("sendingData");
            await saveFormData(data);      
        } catch (e) {
            // console.log("faced error:")
            // console.log(e)
            // console.log(errorData.current)
            setFormState("errorOnSubmit");
        }

    }

    const onError = () => { 
        window.scrollTo(0, 0)
    }

    const dublicateVehicleLine = index => {
        let formValues = getValues();

        append({
            number: formValues.vehicles[index].number,
            brand: formValues.vehicles[index].brand,
            category: formValues.vehicles[index].category,
            targetAreaID: formValues.vehicles[index].targetAreaID,
            comment: formValues.vehicles[index].comment
        })
    }

    const commentLine = (itemID) => {        
        document.getElementById(`${itemID}-propertyComment`).classList.add('visible');
        document.getElementById(`${itemID}-buttonComment`).style.display = "none"; 
    }

    const debug = () => {
        console.log(`errors:`);
        console.log(errors);
    }

    return (
        <>
            <section id="main-form-section" className={addClassName} >
                <div id="loading-screen-filler" className={`${formState === "sendingData" ? "visible" : ""}`}>
                    <LoadingIcon  />
                </div>
                <div id="error-on-submit-container" className={`${formState === "errorOnSubmit" ? "visible" : ""}`}>
                    <p>При отправке произошла ошибка, для организации срочного доступа свяжитесь с дежурным: <br/>+7 (495) 925-10-07<br/>+7 (495) 925-70-08</p>
                    <p>Пожалуйста, свяжитесь с администратором и сообщите ему информацию об ошибке:</p>
                    <p>Код ошибки: <code>{errorData.current?.status}</code></p>
                    <p>Текст ошибки: <code>{errorData.current?.statusText}</code></p>
                </div>
                {
                    formState === "success" &&
                    <h1>Успешно доставлено службе безопасности</h1>
                }
                <form onSubmit={handleSubmit(onSubmit, onError)} className={`${formState === "readyForInput" || formState === "success" ? "visible" : ""}`} >
                    <p id="plate-number-format">Пожалуйста, указывайте номерные знаки полностью и не ставьте лишних пробелов, используйте кириллицу для россиян и латиницу для всех остальных.</p>
                    <ul id="vehiclesList">
                        {
                            fields.length < 1 &&
                                <div className="buttons-container">
                                    <button
                                        type="button"
                                        className="button add" 
                                        onClick={() => {setFormState("readyForInput"); append(defaultVehicleObject.current)}}
                                    >
                                        <p>{`${formState === "success" ? "Повторить" : "Добавить"}`}</p>
                                        <i>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon-square-plus" width="64" height="64" viewBox="0 0 24 24" strokeWidth="2" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <rect x="4" y="4" width="16" height="16" rx="2" />
                                                <line x1="9" y1="12" x2="15" y2="12" />
                                                <line x1="12" y1="9" x2="12" y2="15" />
                                            </svg>
                                        </i>
                                    </button>
                                </div>
                        }
                        {fields.map((item, index) => (
                            <li key={item.id} className="vehicleLine"> 
                                <div className="vehicleProperty">
                                    <label htmlFor={`${item.id}-number`}>Гос. номер</label> 
                                    <input
                                        key={`${item.id}-number`} // important to include key with field's id
                                        className="classyInput"
                                        id={`${item.id}-number`}
                                        {...register(`vehicles.${index}.number`, {required: "Без номера не пустим"})}
                                        defaultValue={item.value} // make sure to include defaultValue
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`vehicles.${index}.number`}
                                        render={({message}) => <span className="errorMessage">{message}</span>}
                                    />
                                </div>
                                <div className="vehicleProperty">
                                    <label htmlFor={`${item.id}-brand`}>Марка</label> 
                                    <Controller
                                        control={control}
                                        name={`vehicles.${index}.brand`}
                                        defaultValue=""
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <CreatableSelect
                                                isClearable
                                                key={`${item.id}-brand`}
                                                id={`${item.id}-brand`}
                                                ref={ref}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                filterOption={createFilter(reasonableFilterConfig)}
                                                onInputChange={onBrandsInputChange}
                                                options={brandsOptions}
                                                placeholder="Начните ввод, выберите подходящую из списка"
                                                formatCreateLabel={(value) => `Добавить новую марку: "${value}"`}
                                                value={value}
                                                classNamePrefix="creatable-select"
                                                isLoading={brandsOptions === undefined}
                                                loadingMessage={() => {return "Идёт загрузка..."}}
                                                noOptionsMessage={() => {return "Нет опций"}}
                                            />
                                        )}
                                        rules={{ required: "Выберите из списка подходящую или создайте новую" }}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`vehicles.${index}.brand`}
                                        render={({message}) => <span className="errorMessage">{message}</span>}
                                    />
                                </div>
                                <div className="vehicleProperty">
                                    <label htmlFor={`${item.id}-category`}>Категория</label> 
                                    <Controller
                                        control={control}
                                        name={`vehicles.${index}.category`}
                                        defaultValue=""
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <Select
                                                key={`${item.id}-category`}
                                                id={`${item.id}-category`}
                                                ref={ref}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                options={categoryOptions}
                                                // defaultValue={categoryOptions[0]}
                                                placeholder="Начните ввод и выберите категорию техники"
                                                isSearchable={true}
                                                value={value}

                                                // className="basic-single"
                                                classNamePrefix="select"

                                            />
                                        )}
                                        rules={{ required: "Выберите из списка" }}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`vehicles.${index}.category`}
                                        render={({message}) => <span className="errorMessage">{message}</span>}
                                    />
                                </div>
                                <div className="vehicleProperty comment" id={`${item.id}-propertyComment`}>
                                    <label htmlFor={`${item.id}-comment`}>Комментарий</label> 
                                    <input
                                        key={`${item.id}-comment`} // important to include key with field's id
                                        className="classyInput"
                                        id={`${item.id}-comment`}
                                        {...register(`vehicles.${index}.comment`)}
                                        defaultValue={""} // make sure to include defaultValue
                                    />
                                </div>
                                <div className="buttons-container">
                                    <button
                                        type="button"
                                        className="button add" 
                                        onClick={() => append(defaultVehicleObject.current)}
                                    >
                                        <p>Добавить</p>
                                        <i>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon-square-plus" width="64" height="64" viewBox="0 0 24 24" strokeWidth="2" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <rect x="4" y="4" width="16" height="16" rx="2" />
                                                <line x1="9" y1="12" x2="15" y2="12" />
                                                <line x1="12" y1="9" x2="12" y2="15" />
                                            </svg>
                                        </i>
                                    </button>
                                    <button className="button comment" id={`${item.id}-buttonComment`} type="button" onClick={() => 
                                        commentLine(item.id)
                                    }>
                                        <p>Сообщение</p>
                                        <i>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon-comment" width="64" height="64" viewBox="0 0 24 24" strokeWidth="2" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1" />
                                                <line x1="12" y1="12" x2="12" y2="12.01" />
                                                <line x1="8" y1="12" x2="8" y2="12.01" />
                                                <line x1="16" y1="12" x2="16" y2="12.01" />
                                            </svg>
                                        </i>
                                    </button>
                                    <button className="button trash" type="button" onClick={() => remove(index)}>
                                        <p>Удалить</p>
                                        <i>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon-trash" width="64" height="64" viewBox="0 0 24 24" strokeWidth="2" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <line x1="4" y1="7" x2="20" y2="7" />
                                                <line x1="10" y1="11" x2="10" y2="17" />
                                                <line x1="14" y1="11" x2="14" y2="17" />
                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                            </svg>
                                        </i>
                                    </button>
                                    <button className="button duplicate" type="button" onClick={() => 
                                        dublicateVehicleLine(index)
                                    }>
                                        <p>Дублировать</p>
                                        <i>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon-copy" width="64" height="64" viewBox="0 0 24 24" strokeWidth="2" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <rect x="8" y="8" width="12" height="12" rx="2" />
                                                <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                                            </svg>
                                        </i>
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <div id="submit-form-container" className={`${formState === "success" ? "invisible" : ""}`}>
                        <ul>
                            <li>Проезд техники будет разрешён только на указанный выше участок</li>
                            <li>Заявки действительны в течение 24 часов с момента отправки</li>
                            <li>Допущенные должны соблюдать правила территории «Ландшафт»</li>
                        </ul>
                        <button className="button" type="submit">Отправить</button>
                        {/* <button className="button" onClick={debug}>debug!</button> */}
                    </div>
                </form>
            </section>
        </>
    )
}

export default MainForm;