import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import './App.scss';

import { useAuthState } from "react-firebase-hooks/auth";

import { MainContext } from "./components/MainContext"
import { auth, getUserData } from "./components/firebase"
import Dashboard from "./components/Dashboard";
import SignIn from "./components/SignIn";
import Page404 from "./components/Page404";


const initialUserData = {
  keyName: undefined,
  UID: undefined,
  allowed: false,
  areas: {},
  targetAreaID: undefined,
  name: undefined,
  phone: undefined,
};

const App = () => {
  const [currentUserData, setCurrentUserData] = useState(initialUserData)
  const [user] = useAuthState(auth);
   
  useEffect(() => {
    if (user) {

      (async () => {
        const userData = await getUserData(user.phoneNumber, user.uid);
        setCurrentUserData(userData);
     })();

    }
  }, [user])

  const value = [
    currentUserData,
    setCurrentUserData
  ]

  return (
    <>
      <MainContext.Provider value={value}>
        <Router>
          <div id="recaptcha-container"></div>
          <Switch>
              <Route path="/" exact>
                <SignIn />
              </Route>
              <Route path="/dashboard" exact>
                <Dashboard />
              </Route>
              <Route path="*">
                <Page404 />
              </Route>
          </Switch>
        </Router> 
      </MainContext.Provider>
    </>
    

  );
}

export default App;