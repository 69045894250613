import { createContext } from "react"

export const MainContext = createContext({
    currentUserData: {
      keyName: undefined,
      UID: undefined,
      allowed: false,
      areas: {},
      targetAreaID: undefined,
      name: undefined,
      phone: undefined,
    },
    changeCurrentUserData: () => {},
  });