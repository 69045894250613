import React, {useState, useRef, useEffect, useContext} from "react";
import { useHistory } from "react-router-dom";

import { updateProfile, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { ref, child, get, set } from "firebase/database";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth, rtdb, getUserData } from "./firebase"
import LoadingIcon from "./LoadingIcon";
import { MainContext } from "./MainContext"
import { updateUserData } from "./littleHelpers";
  

let usersList;
const dbRef = ref(rtdb);  

get(child(dbRef, `users`)).then((snapshot) => {
  if (snapshot.exists()) {
    usersList = snapshot.val();
  } else {
    console.log("No data available");
  }
}).catch((error) => {
  console.error(error);
});


const SignIn = () => {
  const [user, loading, error] = useAuthState(auth);
  const [signInPhase, setSignInPhase] = useState('waitingForValidPhone') 
    //    signInPhases:
    // waitingForValidPhone  
    // wrongPhoneFormat
    // denyAccessForNumber 
    // waitingRecaptcha
    // waitingVerificationCode
    // processing
    // failingVerification
    // success
  const history = useHistory();  
  const signInButton = useRef();
  const phoneNumber = useRef(undefined);
  const [currentUserData, setCurrentUserData] = useContext(MainContext)

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      // console.log("loading")
      // console.log(loading) // returns boolean
      return;
    }
    if (user) {
      history.replace("/dashboard");
    }
  }, [user, loading]);

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        window.recaptchaVerifier.clear();
        // onSignInSubmit();
      }
    }, auth)
    // auth.languageCode = 'ru';
  })

  // this sick part below somehow explaind here:
  // https://stackoverflow.com/questions/46695390/firebase-docs-reference-grecaptcha-but-never-import-or-define-it
  // let grecaptcha; //used it at first, but this doesn't make any sense.
  /* global grecaptcha */

  const updateUserDataInDB = (user) => {
    let currentUserUID = usersList[currentUserData.keyName].UID;

    if (currentUserUID !== user.uid) {
      console.log("currentUserUID !== user.uid from rtdb")
      console.log("setting new UID to rtdb")
      set(ref(rtdb, 'users/' + currentUserData.keyName + "/UID"), user.uid);
      updateUserData(currentUserData, setCurrentUserData, { "UID": user.uid })
    } else {
      updateUserData(currentUserData, setCurrentUserData, { "UID": user.uid })
    }
  
  }

  async function onSignInSubmit() {

    const isProvidedPhoneValid = await isPhoneNumberValid();

    if (isProvidedPhoneValid === true) {
      window.signingIn = true;
      // phoneNumber = getPhoneNumberFromUserInput();
      setSignInPhase('waitingRecaptcha');
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, phoneNumber.current, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          window.signingIn = false;
          // appVerifier.clear();
          setSignInPhase("waitingVerificationCode")
        }).catch(function (error) {
          console.error('Error during signInWithPhoneNumber', error);
          // window.alert('Error during signInWithPhoneNumber:\n\n'
          //     + error.code + '\n\n' + error.message);
          window.signingIn = false;
          setSignInPhase("failingVerification")
        });
    }
  }
      

  function onVerifyCodeSubmit(e) {
    e.preventDefault();

    if (!!getCodeFromUserInput()) {
      window.verifyingCode = true;
      setSignInPhase("processing");
      let code = getCodeFromUserInput();

      window.confirmationResult.confirm(code).then((result) => {
        const user = result.user;
        updateProfile(auth.currentUser, {
          displayName: currentUserData.name,
        }).then(() => {
          console.log("auth.currentUser displayName updated")
        }).catch((error) => {
          console.error('Error while updating user profile', error);
          window.alert('Error while updating user profile:\n\n'
          + error.code + '\n\n' + error.message);
        });

        updateUserDataInDB(user);
        window.verifyingCode = false;
        window.confirmationResult = null;

      }).catch(function (error) {
        // User couldn't sign in (bad verification code?)
        console.error('Error while checking the verification code', error);
        window.alert('Ошибка при проверке кода подтверждения:\n\n'
            + error.code + '\n\n' + error.message);
        window.verifyingCode = false;
        setSignInPhase("failingVerification");
        resetReCaptcha();
      });
    }
  }

  function cancelVerification(e) {
    e.preventDefault();
    window.confirmationResult = null;
    resetReCaptcha();
    setSignInPhase("waitingForValidPhone");
  }

  function getCodeFromUserInput() {
    return document.getElementById('verification-code').value;
  }

  async function isPhoneAllowed() {
    let phoneAllowed = false;

    let providedPhonesUserData = await getUserData(phoneNumber.current);

    if (providedPhonesUserData !== undefined && providedPhonesUserData.allowed === true) {
      updateUserData(currentUserData, setCurrentUserData, providedPhonesUserData)
      phoneAllowed = true;
    }

    return phoneAllowed;

  }
  

   const isPhoneNumberValid = async () => {
    let pattern = /^\+\d{1,3}\d{1,12}/;

    if (phoneNumber.current !== undefined && phoneNumber.current.search(pattern) !== -1) {
      let isAllowed = await isPhoneAllowed();
      if (isAllowed === false) {
        setSignInPhase("denyAccessForNumber");
      } if (isAllowed === true) {        
        return true;
      }
    } else {
      setSignInPhase("wrongPhoneFormat");
      return false;
    }

  }
  const dismissErrors = () => {
    if (signInPhase === "wrongPhoneFormat" || signInPhase === "denyAccessForNumber" ) {
      setSignInPhase("waitingForValidPhone");
    }
  }


  function resetReCaptcha() {
    if (typeof grecaptcha !== 'undefined'
        && typeof window.recaptchaWidgetId !== 'undefined') {
      grecaptcha.reset(window.recaptchaWidgetId);
    }
  }

//// former UI functions
  // function updateVerifyCodeButtonUI() {
  //   document.getElementById('verify-code-button').disabled =
  //       !!window.verifyingCode
  //       || !getCodeFromUserInput();
  // }

  // function updateSignInFormUI() {
  //   if (auth.currentUser || window.confirmationResult) {
  //     document.getElementById('sign-in-form').style.display = 'none';
  //   } else {
  //     resetReCaptcha();
  //     document.getElementById('sign-in-form').style.display = 'block';
  //   }
  // }

  // function updateVerificationCodeFormUI() {
  //   if (!auth.currentUser && window.confirmationResult) {
  //     document.getElementById('verification-code-form').style.display = 'block';
  //   } else {
  //     document.getElementById('verification-code-form').style.display = 'none';
  //   }
  // }


  return (
    <>
    <section id="sign-in-section">
      <div id="wellcome-sign-in">
        <h1>Допуск техники в «Ландшафт»</h1>
        <p>Для представителей строящихся участков с актуальными доверенностями.</p>
        <p>
        Вход в приложение возможен только с помощью номера мобильного телефона,
        согласованного с администрацией. Для подтверждения будет выслано SMS 
        с кодом подтверждения, плата сверх тарифа вашего оператора не взымается.</p>
      </div>
      <div id="sign-in-container">
        <form 
          id="sign-in-form" 
          action="#" 
          style={{display: `${
            signInPhase === "waitingForValidPhone" 
            || 
            signInPhase === "denyAccessForNumber" 
            || 
            signInPhase === "wrongPhoneFormat" ? "flex" : "none"}`
          }}
        >
          <div className="input-container">
              <input type="text" pattern="\+[0-9\s\-\(\)]+" id="phone-number" onChange={(e) => {phoneNumber.current = e.target.value; dismissErrors();}} />
              <label className="label" htmlFor="phone-number">Номер в формате +79991119922</label>
              <span style={{display: `${signInPhase === "denyAccessForNumber" ? "block" : "none"}`}}>Введённого номера среди допущенных нет</span>
              <span style={{display: `${signInPhase === "wrongPhoneFormat" ? "block" : "none"}`}}>Требуется номер в международном стандарте E.164: начинается с плюса, далее цифры без пробелов и других символов</span>
          </div>
          <button 
            className="button" 
            id="sign-in-button" 
            ref={signInButton} 
            onClick={(e) => {
              e.preventDefault();
              onSignInSubmit();
          }}>Войти</button>
        </form>
        <LoadingIcon addClassName={`${signInPhase === "waitingRecaptcha" ? "" : "invisible"}`} />
        <form 
          id="verification-code-form" 
          action="#" 
          style={{display: `${signInPhase === "waitingVerificationCode" ? "flex" : "none"}`}}
          onSubmit={onVerifyCodeSubmit}>
            <div className="input-container">
                <label className="label" htmlFor="verification-code">Введите код под подтверждения из SMS</label>
                <input type="text" id="verification-code"/>
            </div>
            <button type="submit" className="button" id="verify-code-button">Отправить</button>
            <button className="button" id="cancel-verify-code-button" onClick={cancelVerification} >Отмена</button>
        </form>
        <div id="smth-wrong" style={{display: `${signInPhase === "failingVerification" ? "block" : "none"}`}}>
          <p>Что-то пошло не так. Пожалуйста, обновите страницу и попробуйте ещё раз.</p>
        </div>
      </div>
    </section>
    </>
    )
}

export default SignIn;