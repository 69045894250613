import React, { useRef, useEffect, useState, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";

import { auth, logout } from "./firebase";
import { MainContext } from "./MainContext";
import { updateUserData } from "./littleHelpers";

import LoadingIcon from "./LoadingIcon";
import MainForm from "./vehicles-form/MainForm";


const getAreaData = (areaObject) => {

  if (!areaObject) {
    return
  }

  let areasData = [];

  Object.keys(areaObject).forEach((area) => {
    let areaName, notionId;
    // console.log("iterationg data keys", area)
    // console.log("areaObject[area]", areaObject[area])
    // console.log("!!areaObject[area]?.areaName", !!areaObject[area]?.areaName)

    if (!!areaObject[area]?.areaName) {
      areaName = areaObject[area]?.areaName;
      notionId = areaObject[area]?.notionId;
    } else {
      areaName = area;
      notionId = areaObject[area];
    }
      
    areasData.push({
      areaName: areaName,
      notionId: notionId
    })

  });

  console.log("returning areasData", areasData)

  return areasData

}

function Dashboard() {
  const [user, loading] = useAuthState(auth);
  const history = useHistory();
  const [currentUserData, setCurrentUserData] = useContext(MainContext);
  const [isAreaSingle, setAreaSingle] = useState(true);
  const [currentDestinationArea, setCurrentDestinationArea] = useState("");
  const destinationAreaSelectElements = useRef(<><p>ошибка, свяжитесь с администрацией</p></>);
  const userName = useRef("");

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      // console.log("loading")
      // console.log(loading)
      return;
    }
    if (!user) {
      // console.log("no user logged in, redirecting to login page")
      history.replace("/");
    }
  }, [user, loading]);
  
  useEffect(() => {
    let usersFirstAreaData = getAreaData(currentUserData?.areas)?.[0];

    setCurrentDestinationArea(usersFirstAreaData?.areaName);
    updateUserData(currentUserData, setCurrentUserData, { "targetAreaID": usersFirstAreaData?.notionId } )
    userName.current = currentUserData.name;
    
    if (Object.keys(currentUserData.areas).length > 1) {
      destinationAreaSelectElements.current = generateSelectOptions(currentUserData.areas);
      setAreaSingle(false);
    }

  }, [currentUserData, setCurrentUserData])



  const handleLogout = (e) => {
    e.preventDefault();
    console.log("logout()")
    console.log(logout())
    logout()
  }

  const onDestinationChange = (e) => {
    updateUserData(currentUserData, setCurrentUserData, { "targetAreaID": e.target.selectedOptions[0].value } )
    // console.log(e.target.selectedOptions[0].value);
  }

  const generateSelectOptions = (userDataOnAreas) => {
    let options = [];
    let userAreas = getAreaData(userDataOnAreas);

    for (let area of userAreas) {
      options.push(
        <option key={`${area.notionId}`} value={area.notionId}>{area.areaName}</option>
      )
    }

    return (
      <>
        {options}
      </>
    )
  }

  const handleShowUserName = () => {
    document.getElementById("vehicles-destination").style.display = "none";
    document.getElementById("hidden-logged-as").style.display = "none";
    document.getElementById("hidden-destination").style.display = "block";
    document.getElementById("logged-as").style.display = "block";
    document.getElementById("logged-as").style.marginLeft = 0;
    document.getElementById("logged-as").style.borderRight = "none";
    document.getElementsByClassName("logout-button")[0].style.display = "block";
  }
  
  const handleShowDestination = () => {
    document.getElementById("hidden-destination").style.display = "none";
    document.getElementById("logged-as").style.display = "none";
    document.getElementById("logged-as").style.borderRight = "inherit";
    document.getElementById("vehicles-destination").style.display = "block";
    document.getElementById("hidden-logged-as").style.display = "block";
    document.getElementsByClassName("logout-button")[0].style.display = "none";
  }

  const handleDebug = () => {
    console.log("currentDestinationArea")
    console.log(currentDestinationArea)

  }


  return (
    <main id="dashboard">
      <LoadingIcon addClassName={`${ loading ? "" : "invisible"}`} />
      <div id="control-pannel" className={`${ loading ? "invisible" : ""}`}>
        <div id="hidden-logged-as">
          <button className="button show-user-name" onClick={handleShowUserName}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-user" width="64" height="64" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#7bc62d" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <circle cx="12" cy="7" r="4" />
              <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
            </svg>
          </button>
        </div>
        <div id="hidden-destination">
          <button className="button show-destination" onClick={handleShowDestination}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-map-pin" width="64" height="64" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#7bc62d" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <circle cx="12" cy="11" r="3" />
              <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
            </svg>
          </button>
        </div>
        <div id="logged-as">
          <p>Вы вошли как:</p>
          <p id="display-name">{userName.current}</p>
        </div>
        <div id="vehicles-destination">
          {
            isAreaSingle ?
            <>
              <p>Пункт назначения:</p>
              <p>{currentDestinationArea}</p>
            </>
            :
            <>
              <label htmlFor="destination-area-select" id="destination-label">Пункт назначения:</label>
              <select name="areas" id="destination-area-select" onChange={onDestinationChange} tabIndex={0}> 
                {destinationAreaSelectElements.current}
              </select>
            </>
          }
        </div>
        <button className="button logout-button" onClick={handleLogout}>
          <p>выход</p>
          <svg xmlns="http://www.w3.org/2000/svg" className="icon-logout" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#9e9e9e" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
            <path d="M7 12h14l-3 -3m0 6l3 -3" />
          </svg>
        </button>
        {/* <button className="button" onClick={handleDebug}>
          debug
        </button> */}
      </div>
      <MainForm addClassName={`${ loading ? "invisible" : ""}`} />
    </main>
  );
}

export default Dashboard;