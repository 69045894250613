import { initializeApp } from "firebase/app";
import { getDatabase, ref, child, get } from "firebase/database";

import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyANlrbI5LXjaUEo7JlZq13isAKj-SF2atc",
  authDomain: "landshaft-build.firebaseapp.com",
  projectId: "landshaft-build",
  storageBucket: "landshaft-build.appspot.com",
  databaseURL: "https://landshaft-build-default-rtdb.europe-west1.firebasedatabase.app/",
  messagingSenderId: "245971258243",
  appId: "1:245971258243:web:cb77a132663681d88584b6"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();

const rtdb = getDatabase();
const dbRef = ref(rtdb);

async function getUserData ( phoneNumber, providedUID = undefined ) {
  console.log("asking for user data")
  let providedPhonesUserData;
  let currentKeyName;
  let usersList;

  let result = await get(child(dbRef, `users`)).then((snapshot) => {
    if (snapshot.exists()) {
      usersList = snapshot.val();
    } else {
      console.log("No userData available");
    }
  }).then(() => {
    for (let key in usersList) { 
      currentKeyName = key;
      if (usersList[key].phone === phoneNumber) {
        providedPhonesUserData = {
          keyName: currentKeyName,
          allowed: usersList[key].allowed,
          areas: usersList[key].areas,
          name: usersList[key].name,
          phone: phoneNumber,
          UID: providedUID !== undefined ? usersList[key].UID : providedUID,
        }
        return providedPhonesUserData;
      }
    }
  }).catch((error) => {
    console.error(error);
  })

  return result;
  
}

const logout = () => {
  console.log("auth.signOut() called")
  auth.signOut();
};

export {
    auth,
    rtdb,
    getUserData,
    logout,
  };