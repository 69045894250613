//  https://system.landshaft.life/webhook/provide-brands
import { fetchWithTimeout } from "./littleHelpers"

const getBrandsData = async() => {
    // console.log("getBrandsData called")

    const result = await fetchWithTimeout(
        'https://system.landshaft.life/webhook/provide-brands', 
        {
            timeout: 30000,
        }
    )

    const data = await result.json();
    return data.brandsData;

}

export const provideBrandsData = async (setOptions, allOptionsRef) => {
    // console.log("provideBrandsData called")
    const optionName = "brandsOptions";
    const refreshFrequency = 60*60*1000; // 1 hour

    const fetchAndProcessOptions = async () => {
        let tempOptions = await getBrandsData();
        setOptions !== undefined && setOptions(tempOptions);
        allOptionsRef.current = tempOptions;
        window.localStorage.setItem(optionName, JSON.stringify({
            arrayOfOptions: tempOptions,
            dateOfInsertion: new Date()
        }))
    }
    

    if (!window.localStorage.getItem(optionName)) {
        await fetchAndProcessOptions();
    } else {
        let tempOptions = JSON.parse(window.localStorage.getItem(optionName));
        
        if (Date.now() - Date.parse(tempOptions.dateOfInsertion) < refreshFrequency) {
            setOptions !== undefined && setOptions(tempOptions.arrayOfOptions);
            allOptionsRef.current = tempOptions.arrayOfOptions;
        } else {
            await fetchAndProcessOptions();
        }
    }

    return allOptionsRef.current;


}

 